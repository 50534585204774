import { axiosInstance } from "../../utils/axiosConfig";
import {
  CLEAR_STATS_ERRORS,
  STATS_FAIL,
  STATS_REQUEST,
  STATS_SUCCESS,
} from "../constants/statsConstant";

// GET STATS
export const getStats = () => async (dispatch) => {
  try {
    dispatch({
      type: STATS_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("jsrConfig")
      ? JSON.parse(sessionStorage.getItem("jsrConfig"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/stats`, config);

    dispatch({
      type: STATS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STATS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearStatsErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATS_ERRORS });
};
