import React, { useEffect } from "react";
import SidebarLayout from "../components/layouts/SidebarLayout";
import PageTitle from "../components/PageTitle";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  clearPolicyErrors,
  getAllPolicies,
} from "../redux/actions/policyAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowRight, RxDoubleArrowLeft } from "react-icons/rx";
import usePaginate from "../hooks/usePaginate";
import "../styles/tableDataStyle.scss";
// import { FaRegEdit } from "react-icons/fa";
import NoData from "../components/NoData";

const AllPolicies = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    loading,
    data: allPolicies,
    error,
  } = useSelector((state) => state.getAllPoliciesReducer);

  useEffect(() => {
    dispatch(getAllPolicies());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearPolicyErrors());
    }
  }, [error, dispatch]);

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick } = usePaginate(
    allPolicies?.policies
  );
  // Pagination Hook Ends
  return loading ? (
    <Loader />
  ) : (
    <div className="AddPolicy_main">
      <SidebarLayout>
        <div className="AddPolicy_content">
          <PageTitle title="All Policies" />
          <button
            className="btn btn-primary ms-auto d-block"
            onClick={() => navigate("/add-policy")}
          >
            Add New Policy
          </button>
          <div className="agents_table mt-4">
            {!allPolicies?.policies.length ? (
              <NoData content="Policies not found" />
            ) : (
              <>
                <div className="table-responsive">
                  <table className="table  table-hover table-bordered customTable">
                    <thead>
                      <tr>
                        <th scope="col">Policy No</th>
                        <th scope="col">Policy Holder</th>
                        <th scope="col">Insurer (Company)</th>
                        <th scope="col">Valid Till (Date)</th>
                        <th scope="col">Premium</th>
                        <th scope="col">Associate's Points</th>
                        <th scope="col">Referred By</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.map((itm, itmIndex) => (
                        <tr key={itmIndex}>
                          <td>
                            {itm?.policyNumber ? itm?.policyNumber : "null"}
                          </td>
                          <td>{itm?.policyHolder}</td>
                          <td>{itm?.insurerCompany?.companyName}</td>
                          <td>
                            {moment(itm?.policyStartDate).format("DD-MM-YYYY")}{" "}
                            - {moment(itm?.policyEndDate).format("DD-MM-YYYY")}
                          </td>
                          <td>₹ {itm?.premium}</td>
                          <td>{itm?.policyReffererCommission}</td>
                          <td>
                            {itm?.policyRefferer?._id
                              ? itm?.policyRefferer?.fullName +
                                `(${itm?.policyRefferer?.panNumber})`
                              : "null"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="pagination_sec">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<RxDoubleArrowRight />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={<RxDoubleArrowLeft />}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </SidebarLayout>
    </div>
  );
};

export default AllPolicies;
