import React, { useEffect } from "react";
import "../styles/dashboardStyle.scss";
import SidebarLayout from "../components/layouts/SidebarLayout";
import PageTitle from "../components/PageTitle";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearStatsErrors, getStats } from "../redux/actions/statsAction";
import { toast } from "react-toastify";
import Loader from "../components/Loader";

const Card = ({ title, value, btnText, buttonLink }) => {
  const navigate = useNavigate();
  return (
    <div
      className="custom-card card rounded h-100"
      style={{ boxShadow: `1px 1px 1px 1px #111` }}
    >
      <h5 className="text-center text-uppercase rounded text-light p-2 border-bottom" style={{background: "#42275a"}}>
        {title}
      </h5>
      <p
        className={`mx-2 text-center ${
          typeof value === "number" ? "fs-4 fw-bold" : ""
        } `}
        style={{color: "#42275a"}}
      >
        {value}
      </p>
      <button
        className="btn border d-block mx-auto text-uppercase mb-2"
        onClick={() => navigate(buttonLink)}
      >
        {btnText ? btnText : "Manage"}
      </button>
    </div>
  );
};

const Dashboard = () => {
  const dispatch = useDispatch();

  const { loading, stats, error } = useSelector((state) => state.stats);

  useEffect(() => {
    dispatch(getStats());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearStatsErrors());
    }
  }, [dispatch, error]);

  const contentCardArr = [
    {
      title: "All Policies",
      value: stats?.policies,
      buttonText: "Manage",
      buttonLink: "/all-policies",
    },
    {
      title: "Add Policy",
      value: "Add New Policy which refferred by associates",
      buttonText: "Add New",
      buttonLink: "/add-policy",
    },
    {
      title: "Insurer Companies",
      value: stats?.insurerCompanies,
      buttonText: "Manage",
      buttonLink: "/attached-companies",
    },
    {
      title: "Associates",
      value: stats?.agents,
      buttonText: "Manage",
      buttonLink: "/agents",
    },
    {
      title: "Admins",
      value: stats?.admins,
      buttonText: "Manage",
      buttonLink: "/admins",
    },
    {
      title: "Designations",
      value: stats?.designations,
      buttonText: "Manage",
      buttonLink: "/designations",
    },
    {
      title: "Points",
      value:
        "Click on bottom 'Check' button, to see Points earnings of all associates",
      buttonText: "Check/Manage",
      buttonLink: "/commisions",
    },
    {
      title: "Revenue",
      value: 0,
      buttonText: "Check",
      buttonLink: "/revenue",
    },
    {
      title: "Important Updates",
      value: "Send important updates to all users",
      buttonText: "Update New Message",
      buttonLink: "/important-update",
    },
  ];
  return loading ? (
    <Loader />
  ) : (
    <div className="dashboard_main">
      <SidebarLayout>
        <div className="dashboard_content">
          <PageTitle title="Dashboard" />
          <div className="row g-3">
            {contentCardArr?.map((item, itemIndex) => {
              return (
                <div className="col-md-4 col-6" key={itemIndex}>
                  <Card
                    title={item?.title}
                    value={item?.value}
                    btnText={item?.buttonText}
                    buttonLink={item?.buttonLink}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </SidebarLayout>
    </div>
  );
};

export default Dashboard;
