import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import {
  getAllUsersReducer,
  getUserByPanNumberReducer,
  getUserDetailReducer,
  updateUserDetailReducer,
  updateUserPasswordReducer,
  userLoginReducer,
  userProfileReducer,
  userRegister,
} from "./reducers/userReducer.js";
import { statsReducer } from "./reducers/statsReducer.js";
import {
  createPolicyReducer,
  getAllPoliciesReducer,
} from "./reducers/policyReducer.js";
import {
  createDesignationsReducer,
  deleteDesignationReducer,
  getAllDesignationsReducer,
  updateDesignationReducer,
} from "./reducers/designationReducer.js";
import {
  addCompanyReducer,
  deleteCompanyReducer,
  getAllCompaniesReducer,
  updateCompanyReducer,
} from "./reducers/insurerCompanyReducer.js";

// get user from sessionStorage
const userFromLocalStorage = sessionStorage.getItem("jsrConfig")
  ? JSON.parse(sessionStorage.getItem("jsrConfig"))
  : {};

// COMBINE ALL REDUCERS
const reducer = combineReducers({
  register: userRegister,
  userLogin: userLoginReducer,
  profile: userProfileReducer,
  getUserByPanNumber: getUserByPanNumberReducer,
  getAllUsers: getAllUsersReducer,
  getUserDetail: getUserDetailReducer,
  updateUserDetail: updateUserDetailReducer,
  updatePassword: updateUserPasswordReducer,
  stats: statsReducer,

  // policy reducers start
  createPolicyReducer: createPolicyReducer,
  getAllPoliciesReducer: getAllPoliciesReducer,
  // policy reducers ends

  // designation reducers start
  getAllDesignations: getAllDesignationsReducer,
  createDesignation: createDesignationsReducer,
  updateDesignation: updateDesignationReducer,
  deleteDesignation: deleteDesignationReducer,
  // designation reducers ends

  // insurer company reducers start
  getAllCompanies: getAllCompaniesReducer,
  addCompanies: addCompanyReducer,
  updateCompany: updateCompanyReducer,
  deleteCompany: deleteCompanyReducer,
  // insurer company reducers ends
});

// INITIAL STATE
const initialState = {
  userLogin: {
    userInfo: userFromLocalStorage,
  },
};

// MIDDLEWARE // THUNK
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  applyMiddleware(...middleware)
);

export default store;
