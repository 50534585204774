export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_STATE = "CLEAR_STATE";

export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAIL = "USER_PROFILE_FAIL";

export const GET_USER_BY_PAN_REQUEST = "GET_USER_BY_PAN_REQUEST";
export const GET_USER_BY_PAN_SUCCESS = "GET_USER_BY_PAN_SUCCESS";
export const GET_USER_BY_PAN_FAIL = "GET_USER_BY_PAN_FAIL";

export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";

export const GET_USER_DETAILS_REQUEST = "GET_USER_DETAILS_REQUEST";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAIL";

export const UPDATE_USER_DETAILS_REQUEST = "UPDATE_USER_DETAILS_REQUEST";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAIL = "UPDATE_USER_DETAILS_FAIL";

export const CHNAGE_PASSWORD_REQUEST = "CHNAGE_PASSWORD_REQUEST";
export const CHNAGE_PASSWORD_SUCCESS = "CHNAGE_PASSWORD_SUCCESS";
export const CHNAGE_PASSWORD_FAIL = "CHNAGE_PASSWORD_FAIL";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";

export const CLEAR_PROFILE_ERRORS = "CLEAR_PROFILE_ERRORS";
export const CLEAR_USER_LOADING = "CLEAR_USER_LOADING";
export const CLEAR_REGISTER_STATE = "CLEAR_REGISTER_STATE";
export const CLEAR_DETAILS_STATE = "CLEAR_DETAILS_STATE";
export const CLEAR_UPDATE_STATE = "CLEAR_UPDATE_STATE";
