import React from 'react'

const NoData = ({content}) => {
  return (
    <div className='no_data card border rounded w-100 bg-dark text-light py-3'>
        <p className="mb-0 fs-5 text-center">{content ? content : "Data not found"}</p>
    </div>
  )
}

export default NoData