import React from "react";

const LoadingButton = ({ colorClass }) => {
  return (
    <>
      <span
        class={`spinner-grow spinner-grow-sm me-1 ${colorClass}`}
        role="status"
        aria-hidden="true"
      ></span>
      Loading...
    </>
  );
};

export default LoadingButton;
