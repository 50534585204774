import {
  ALL_COMPANIES_FAIL,
  ALL_COMPANIES_REQUEST,
  ALL_COMPANIES_SUCCESS,
  CLEAR_COMPANY_ERROR,
  CLEAR_COMPANY_STATE,
  CREATE_COMPANY_FAIL,
  CREATE_COMPANY_REQUEST,
  CREATE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANY_SUCCESS,
  UPADTE_COMPANY_FAIL,
  UPADTE_COMPANY_REQUEST,
  UPADTE_COMPANY_SUCCESS,
} from "../constants/insurerCompanyConstant.js";

// GET ALL COMPANIES REDUCER
export const getAllCompaniesReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_COMPANIES_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case ALL_COMPANIES_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case ALL_COMPANIES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_COMPANY_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// ADD COMPANY REDUCER
export const addCompanyReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_COMPANY_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case CREATE_COMPANY_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case CREATE_COMPANY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_COMPANY_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_COMPANY_STATE:
      return {
        data: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// UPDATE COMPANY REDUCER
export const updateCompanyReducer = (state = {}, action) => {
  switch (action.type) {
    case UPADTE_COMPANY_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case UPADTE_COMPANY_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case UPADTE_COMPANY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_COMPANY_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_COMPANY_STATE:
      return {
        data: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// DELETE COMPANY REDUCER
export const deleteCompanyReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_COMPANY_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case DELETE_COMPANY_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case DELETE_COMPANY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_COMPANY_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_COMPANY_STATE:
      return {
        data: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
