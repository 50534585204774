export const ALL_DESIGNATIONS_REQUEST = "ALL_DESIGNATIONS_REQUEST";
export const ALL_DESIGNATIONS_SUCCESS = "ALL_DESIGNATIONS_SUCCESS";
export const ALL_DESIGNATIONS_FAIL = "ALL_DESIGNATIONS_FAIL";

export const CREATE_DESIGNATIONS_REQUEST = "CREATE_DESIGNATIONS_REQUEST";
export const CREATE_DESIGNATIONS_SUCCESS = "CREATE_DESIGNATIONS_SUCCESS";
export const CREATE_DESIGNATIONS_FAIL = "CREATE_DESIGNATIONS_FAIL";

export const UPADTE_DESIGNATION_REQUEST = "UPADTE_DESIGNATION_REQUEST";
export const UPADTE_DESIGNATION_SUCCESS = "UPADTE_DESIGNATION_SUCCESS";
export const UPADTE_DESIGNATION_FAIL = "UPADTE_DESIGNATION_FAIL";

export const DELETE_DESIGNATION_REQUEST = "DELETE_DESIGNATION_REQUEST";
export const DELETE_DESIGNATION_SUCCESS = "DELETE_DESIGNATION_SUCCESS";
export const DELETE_DESIGNATION_FAIL = "DELETE_DESIGNATION_FAIL";

export const CLEAR_DESIGNATION_ERROR = "CLEAR_DESIGNATION_ERROR";
export const CLEAR_DESIGNATION_STATE = "CLEAR_DESIGNATION_STATE";
