import React, { useEffect, useState } from "react";
import "../styles/addDesignation.scss";
import SidebarLayout from "../components/layouts/SidebarLayout";
import PageTitle from "../components/PageTitle";
import CustomForm from "../components/CustomForm";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import usePaginate from "../hooks/usePaginate";
import NoData from "../components/NoData";
import { FaRegEdit } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import Loader from "../components/Loader";
import LoadingButton from "../components/LoadingButton";
import { MdClose, MdDelete } from "react-icons/md";
import {
  addNewCompany,
  clearCompanyErrors,
  clearCompanyState,
  deleteCompany,
  getAllCompanies,
  updateCompany,
} from "../redux/actions/insurerCompanyAction";

const UpdateModal = ({ showModal, setShowModal, selectedCompany }) => {
  const dispatch = useDispatch();
  const [companyName, setCompanyName] = useState("");
  const [companyCode, setCompanyCode] = useState("");

  const { loading, data, error } = useSelector((state) => state.updateCompany);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearCompanyErrors());
    }
    if (data?.success) {
      toast.success(data?.message);
      dispatch(getAllCompanies());
      setCompanyName("");
      setCompanyCode("");
      setShowModal(false);
      dispatch(clearCompanyState());
    }
  }, [data?.message, data?.success, dispatch, error, setShowModal]);

  const handleUpdateCompany = () => {
    dispatch(updateCompany(selectedCompany?._id, companyName, companyCode));
  };

  const handleCloseModal = () => {
    setCompanyName("");
    setCompanyCode("");
    setShowModal(false);
  };
  return (
    <div
      className={`update_designation position-fixed rounded bg-dark text-light p-3 ${
        showModal ? "show_modal" : ""
      }`}
    >
      <button
        className="btn btn-danger ms-auto d-block mb-2"
        onClick={() => handleCloseModal()}
      >
        <MdClose />
      </button>
      <CustomForm>
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="form-group">
              <label className="fw-bold text-dark" htmlFor="CompanyName">
                Company Name
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="CompanyName"
                placeholder="Ex: TATA MFD"
                value={companyName ? companyName : selectedCompany?.companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="fw-bold text-dark" htmlFor="CompanyCode">
                Company Code
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="CompanyCode"
                placeholder="Ex: TATA MFD"
                value={companyCode ? companyCode : selectedCompany?.companyCode}
                onChange={(e) => setCompanyCode(e.target.value)}
              />
            </div>
          </div>
        </div>
        <button
          onClick={handleUpdateCompany}
          className="btn btn-danger d-block mx-auto mt-3 text-uppercase"
        >
          {loading ? <LoadingButton /> : "Update"}
        </button>
      </CustomForm>
    </div>
  );
};

const AttachedCompanies = () => {
  const dispatch = useDispatch();
  const [companyName, setCompanyName] = useState("");
  const [companyCode, setCompanyCode] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState();

  const {
    loading,
    data: allCompanies,
    error,
  } = useSelector((state) => state.getAllCompanies);

  useEffect(() => {
    dispatch(getAllCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearCompanyErrors());
    }
  }, [error, dispatch]);

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick } = usePaginate(
    allCompanies?.companies
  );
  // Pagination Hook Ends

  // CREATE NEW COMPANY START
  const {
    loading: addCompanyLoading,
    data: addCompanyData,
    error: addCompanyError,
  } = useSelector((state) => state.addCompanies);

  const handleCreateCompany = () => {
    dispatch(addNewCompany(companyName, companyCode));
  };

  useEffect(() => {
    if (addCompanyError) {
      toast.error(addCompanyError);
      dispatch(clearCompanyErrors());
    }

    if (addCompanyData?.success) {
      toast.success(addCompanyData?.message);
      dispatch(clearCompanyState());
      dispatch(getAllCompanies());
      setCompanyName("");
      setCompanyCode("");
    }
  }, [
    addCompanyError,
    dispatch,
    addCompanyData?.success,
    addCompanyData?.message,
  ]);

  // CREATE NEW COMPANY ENDS

  // DELETE THE COMPANY START
  const {
    loading: deleteLoading,
    data: deleteData,
    error: deleteError,
  } = useSelector((state) => state.deleteCompany);

  const handleDeleteCompany = (id) => {
    dispatch(deleteCompany(id));
  };

  useEffect(() => {
    if (deleteError) {
      toast.error(deleteError);
      dispatch(clearCompanyErrors());
    }
    if (deleteData?.success) {
      toast.success(deleteData?.message);
      dispatch(getAllCompanies());
      dispatch(clearCompanyState());
    }
  }, [deleteData?.message, deleteData?.success, deleteError, dispatch]);

  // DELETE THE DESIGNATION ENDS

  const handleUpdateModal = (itm) => {
    if (itm?._id) {
      setShowModal(true);
      setSelectedCompany(itm);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="AddPolicy_main">
      <SidebarLayout>
        <div className="AddPolicy_content">
          <PageTitle title="Attached/Insurer Companies" />
          <CustomForm>
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="companyName">
                    Company Name
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="companyName"
                    placeholder="Ex: MFD Insurance Ltd"
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="companyCode">
                    Company Code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="companyCode"
                    placeholder="Ex: MFD000X123"
                    onChange={(e) => setCompanyCode(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <button
              onClick={handleCreateCompany}
              className="btn btn-success d-block mx-auto mt-3 text-uppercase"
            >
              {addCompanyLoading ? <LoadingButton /> : "Add Company"}
            </button>
          </CustomForm>

          <div className="designation_list mt-4">
            {!allCompanies?.companies?.length ? (
              <NoData />
            ) : (
              <>
                <div className="table-responsive">
                  <table className="table  table-hover table-bordered customTable">
                    <thead>
                      <tr>
                        <th scope="col">Company Name</th>
                        <th scope="col">Company Code</th>
                        <th scope="col">Created (Date)</th>
                        <th scope="col">Created By</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.map((itm, itmIndex) => (
                        <tr key={itmIndex}>
                          <td>{itm?.companyName}</td>
                          <td>{itm?.companyCode}</td>
                          <td>
                            {moment(itm?.createdAt).format("MMM DD, YYYY")}
                          </td>
                          <td>{itm?.createdBy.fullName} ({itm?.createdBy.panNumber}) </td>
                          <td>
                            <div className="d-flex">
                              <button
                                className="btn d-flex btn-primary"
                                title="Edit/See Details"
                                onClick={() => handleUpdateModal(itm)}
                              >
                                <FaRegEdit />
                              </button>
                              <button
                                className="btn d-flex btn-danger ms-2"
                                title="Delete row"
                                onClick={() => handleDeleteCompany(itm?._id)}
                              >
                                {deleteLoading ? (
                                  <LoadingButton />
                                ) : (
                                  <MdDelete />
                                )}
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="pagination_sec">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<RxDoubleArrowRight />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={<RxDoubleArrowLeft />}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </SidebarLayout>
      {/* UPDATE MODAL START */}
      <UpdateModal
        showModal={showModal}
        setShowModal={setShowModal}
        selectedCompany={selectedCompany}
      />
      {/* UPDATE MODAL ENDS */}
    </div>
  );
};

export default AttachedCompanies;
