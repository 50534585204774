import {
  CLEAR_ERRORS,
  CLEAR_STATE,
  REGISTER_FAIL,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAIL,
  CLEAR_PROFILE_ERRORS,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  CLEAR_USER_LOADING,
  GET_USER_BY_PAN_REQUEST,
  GET_USER_BY_PAN_SUCCESS,
  GET_USER_BY_PAN_FAIL,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAIL,
  CLEAR_REGISTER_STATE,
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  UPDATE_USER_DETAILS_REQUEST,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAIL,
  CLEAR_UPDATE_STATE,
  CHNAGE_PASSWORD_REQUEST,
  CHNAGE_PASSWORD_SUCCESS,
  CHNAGE_PASSWORD_FAIL,
} from "../constants/userConstant.js";

// FOR USER REGISTRATION
export const userRegister = (state = {}, action) => {
  switch (action.type) {
    case REGISTER_REQUEST:
      return {
        loading: true,
        userInfo: {},
      };
    case REGISTER_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case REGISTER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case CLEAR_REGISTER_STATE:
      return {
        loading: false,
        userInfo: {},
      };

    default:
      return state;
  }
};

// USER LOGIN REDUCER
export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
        userInfo: {},
      };

    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        userInfo: action.payload,
      };

    case USER_LOGIN_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        loading: false,
        isAuthenticated: false,
        userInfo: null,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        isAuthenticated: false,
        error: null,
        userInfo: {},
      };
    case CLEAR_USER_LOADING:
      return {
        ...state,
        loading: false,
      };

    case USER_LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        userInfo: {},
      };

    default:
      return state;
  }
};

// GET USER PROFILE REDUCER
export const userProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
        userInfo: {},
      };
    case USER_PROFILE_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        userInfo: action.payload,
      };
    case USER_PROFILE_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      };
    case CLEAR_PROFILE_ERRORS:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: null,
      };

    case USER_LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        userInfo: {},
      };

    default:
      return state;
  }
};

// GET USER BY PAN NUMBER REDUCER
export const getUserByPanNumberReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_BY_PAN_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case GET_USER_BY_PAN_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case GET_USER_BY_PAN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET USER DETAILS REDUCER
export const getUserDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_DETAILS_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case GET_USER_DETAILS_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case GET_USER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// UPDATE USER DETAILS REDUCER
export const updateUserDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_USER_DETAILS_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case UPDATE_USER_DETAILS_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case UPDATE_USER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_UPDATE_STATE:
      return {
        data: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET ALL USERS REDUCER
export const getAllUsersReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_USERS_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case GET_ALL_USERS_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case GET_ALL_USERS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET ALL USERS REDUCER
export const updateUserPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case CHNAGE_PASSWORD_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case CHNAGE_PASSWORD_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case CHNAGE_PASSWORD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_UPDATE_STATE:
      return {
        data: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
