import {
  CLEAR_STATS_ERRORS,
  STATS_FAIL,
  STATS_REQUEST,
  STATS_SUCCESS,
} from "../constants/statsConstant";

// GET STATS REDUCER
export const statsReducer = (state = {}, action) => {
  switch (action.type) {
    case STATS_REQUEST:
      return {
        loading: true,
        stats: {},
      };
    case STATS_SUCCESS:
      return {
        loading: false,
        stats: action.payload,
      };
    case STATS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_STATS_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
