import React from "react";
import "../styles/importantUpdates.scss";
import SidebarLayout from "../components/layouts/SidebarLayout";
import PageTitle from "../components/PageTitle";
import CustomForm from "../components/CustomForm";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";

const ImportantUpdates = () => {
  // GET RANDOM LIGHT COLOR
  function getRandomLightColors() {
    const colorArr = [];

    for (let index = 0; index < 10; index++) {
      const r = Math.floor(200 + Math.random() * 55); // Between 200 and 255
      const g = Math.floor(200 + Math.random() * 55); // Between 200 and 255
      const b = Math.floor(200 + Math.random() * 55); // Between 200 and 255
      colorArr.push(`rgb(${r}, ${g}, ${b})`);
    }

    return colorArr;
  }

  const colorArr = getRandomLightColors();

  const messageArr = [1, 1, 1, 1, 1];

  return (
    <div className="updates_main">
      <SidebarLayout>
        <div className="updates_content">
          <PageTitle title="Important Updates" />
          <div className="updates_data">
            <CustomForm>
              <div className="form-group">
                <label className="fw-bold" htmlFor="importantUpdate">
                  Write You message here <span className="text-danger">*</span>
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="importantUpdate"
                  placeholder="Ex: Write your imporatnt message..."
                  rows={5}
                />
                <button className="btn btn-success d-block mx-auto mt-3 text-uppercase">
                  Send Message
                </button>
              </div>
            </CustomForm>

            <div className="last_messages mt-4">
              <ol>
                {messageArr?.map((message, messageIndex) => {
                  return (
                    <li
                      className="text-light"
                      style={{ backgroundColor: colorArr[messageIndex] }}
                    >
                      <p>
                        <span className="text-danger">
                          <b>Sent By:</b> Rohit Malik
                        </span>
                        <span className="text-danger">
                          <b>Date:</b> November-26-2023
                        </span>
                      </p>
                      <p className="text-dark">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Quos molestiae quia incidunt modi delectus, sunt
                        unde ab nihil qui? Nemo, ipsa. Nisi, enim qui. Error
                        minus sit quisquam maxime reprehenderit?
                      </p>
                      <div className="btn_sec mt-2">
                        <button className="btn btn-warning d-inline-flex" title="Edit">
                          <CiEdit />
                        </button>
                        <button className="btn btn-danger d-inline-flex ms-2" title="Delete">
                          <MdDelete />
                        </button>
                      </div>
                    </li>
                  );
                })}
              </ol>
            </div>
          </div>
        </div>
      </SidebarLayout>
    </div>
  );
};

export default ImportantUpdates;
