import React from "react";
import SidebarLayout from "../components/layouts/SidebarLayout";
import PageTitle from "../components/PageTitle";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import CustomForm from "../components/CustomForm";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Revenue = () => {
  // Sample data
  const revenueData = {
    today: 500,
    yesterday: 450,
    lastMonth: 12000,
    lastYear: 150000,
    dateRange: [],
  };

  // Sample chart data
  const chartData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Monthly Revenue",
        data: [
          12000, 15000, 17000, 14000, 16000, 18000, 20000, 22000, 21000, 23000,
          24000, 25000,
        ],
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
      },
    ],
  };

  const handleDateRangeChange = (e) => {
    // Handle date range change
  };
  return (
    <div className="revenue_main">
      <SidebarLayout>
        <div className="revenue_content">
          <PageTitle title="Revenue" />
          <div className="revenue_data">
            <Row>
              <Col md={6}>
                <Card className="mb-4">
                  <Card.Body>
                    <Card.Title>Today's Revenue</Card.Title>
                    <Card.Text>${revenueData.today}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="mb-4">
                  <Card.Body>
                    <Card.Title>Last Month's Revenue</Card.Title>
                    <Card.Text>${revenueData.yesterday}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <div className="mb-4">
              <CustomForm>
                <h5 className="text-dark">Filter Revenue By Date</h5>
                <Row>
                  <Col>
                    <Form>
                      <Row>
                        <Col md={5}>
                          <Form.Group controlId="startDate">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                              type="date"
                              onChange={handleDateRangeChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={5}>
                          <Form.Group controlId="endDate">
                            <Form.Label>End Date</Form.Label>
                            <Form.Control
                              type="date"
                              onChange={handleDateRangeChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={2} className="d-flex align-items-end">
                          <Button variant="primary" type="submit">
                            Filter
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </CustomForm>
            </div>

            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title>Revenue Trends</Card.Title>
                    <Line data={chartData} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </SidebarLayout>
    </div>
  );
};

export default Revenue;
