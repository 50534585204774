import React, { useEffect, useState } from "react";
import "../styles/addDesignation.scss";
import SidebarLayout from "../components/layouts/SidebarLayout";
import PageTitle from "../components/PageTitle";
import CustomForm from "../components/CustomForm";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import usePaginate from "../hooks/usePaginate";
import NoData from "../components/NoData";
import { FaRegEdit } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import Loader from "../components/Loader";
import {
  clearDesignationErrors,
  clearDesignationState,
  createDesignation,
  deleteDesignation,
  getAllDesignation,
  updateDesignation,
} from "../redux/actions/designationAction";
import LoadingButton from "../components/LoadingButton";
import { MdClose, MdDelete } from "react-icons/md";

const UpdateModal = ({ showModal, setShowModal, selectedDesignation }) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");

  const { loading, data, error } = useSelector(
    (state) => state.updateDesignation
  );

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearDesignationErrors());
    }
    if (data?.success) {
      toast.success(data?.message);
      dispatch(getAllDesignation());
      dispatch(clearDesignationState());
    }
  }, [data?.message, data?.success, dispatch, error]);

  const handleUpdateDesignation = () => {
    dispatch(updateDesignation(selectedDesignation?._id, title));
  };

  const handleCloseModal = () => {
    setTitle("");
    setShowModal(false);
  };
  return (
    <div
      className={`update_designation position-fixed rounded bg-dark text-light p-3 ${
        showModal ? "show_modal" : ""
      }`}
    >
      <button
        className="btn btn-danger ms-auto d-block mb-2"
        onClick={() => handleCloseModal()}
      >
        <MdClose />
      </button>
      <CustomForm>
        <div className="row justify-content-center">
          <div className="col-md-12 col">
            <div className="form-group">
              <label className="fw-bold text-dark" htmlFor="designation">
                Designation Title
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="designation"
                placeholder="Ex: Area Manager"
                value={title ? title : selectedDesignation?.title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </div>
        </div>
        <button
          onClick={handleUpdateDesignation}
          className="btn btn-danger d-block mx-auto mt-3 text-uppercase"
        >
          {loading ? <LoadingButton /> : "Update"}
        </button>
      </CustomForm>
    </div>
  );
};

const AddDesignation = () => {
  const dispatch = useDispatch();
  const [designationTitle, setDesignationTitle] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedDesignation, setSelectedDesignation] = useState();

  const {
    loading,
    data: allDesignations,
    error,
  } = useSelector((state) => state.getAllDesignations);

  useEffect(() => {
    dispatch(getAllDesignation());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearDesignationErrors());
    }
  }, [error, dispatch]);

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick } = usePaginate(
    allDesignations?.designations
  );
  // Pagination Hook Ends

  // CREATE NEW DESIGNATION START
  const {
    loading: createDesignationLoading,
    data: createDesignationData,
    error: createDesignationError,
  } = useSelector((state) => state.createDesignation);

  const handleCreateDesignation = () => {
    dispatch(createDesignation(designationTitle));
  };

  useEffect(() => {
    if (createDesignationError) {
      toast.error(createDesignationError);
      dispatch(clearDesignationErrors());
    }

    if (createDesignationData?.success) {
      toast.success(createDesignationData?.message);
      dispatch(clearDesignationState());
      dispatch(getAllDesignation());
      setDesignationTitle("");
    }
  }, [
    createDesignationError,
    dispatch,
    createDesignationData?.success,
    createDesignationData?.message,
  ]);

  // CREATE NEW DESIGNATION ENDS

  // DELETE THE DESIGNATION START
  const {
    loading: deleteLoading,
    data: deleteData,
    error: deleteError,
  } = useSelector((state) => state.deleteDesignation);

  const handleDeleteDesignation = (id) => {
    dispatch(deleteDesignation(id));
  };

  useEffect(() => {
    if (deleteError) {
      toast.error(deleteError);
      dispatch(clearDesignationErrors());
    }
    if (deleteData?.success) {
      toast.success(deleteData?.message);
      dispatch(getAllDesignation());
      dispatch(clearDesignationState());
    }
  }, [deleteData?.message, deleteData?.success, deleteError, dispatch]);

  // DELETE THE DESIGNATION ENDS

  const handleUpdateModal = (itm) => {
    if (itm?._id) {
      setShowModal(true);
      setSelectedDesignation(itm);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="AddPolicy_main">
      <SidebarLayout>
        <div className="AddPolicy_content">
          <PageTitle title="Designations" />
          <CustomForm>
            <div className="row justify-content-center">
              <div className="col-md-6 col">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="designation">
                    Designation Title
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="designation"
                    placeholder="Ex: Area Manager"
                    onChange={(e) => setDesignationTitle(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <button
              onClick={handleCreateDesignation}
              className="btn btn-success d-block mx-auto mt-3 text-uppercase"
            >
              {createDesignationLoading ? (
                <LoadingButton />
              ) : (
                "Create New Designation"
              )}
            </button>
          </CustomForm>

          <div className="designation_list mt-4">
            {!allDesignations?.designations?.length ? (
              <NoData />
            ) : (
              <>
                <div className="table-responsive">
                  <table className="table  table-hover table-bordered customTable">
                    <thead>
                      <tr>
                        <th scope="col">Designation (Title)</th>
                        <th scope="col">Created (Date)</th>
                        <th scope="col">Created By</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.map((itm, itmIndex) => (
                        <tr key={itmIndex}>
                          <td>{itm?.title}</td>
                          <td>
                            {moment(itm?.createdAt).format("MMM DD, YYYY")}
                          </td>
                          <td>{itm?.createdBy.fullName}</td>
                          <td>
                            <div className="d-flex">
                              <button
                                className="btn d-flex btn-primary"
                                title="Edit/See Details"
                                onClick={() => handleUpdateModal(itm)}
                              >
                                <FaRegEdit />
                              </button>
                              <button
                                className="btn d-flex btn-danger ms-2"
                                title="Delete row"
                                onClick={() =>
                                  handleDeleteDesignation(itm?._id)
                                }
                              >
                                {deleteLoading ? (
                                  <LoadingButton />
                                ) : (
                                  <MdDelete />
                                )}
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="pagination_sec">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<RxDoubleArrowRight />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={<RxDoubleArrowLeft />}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </SidebarLayout>
      {/* UPDATE MODAL START */}
      <UpdateModal
        showModal={showModal}
        setShowModal={setShowModal}
        selectedDesignation={selectedDesignation}
      />
      {/* UPDATE MODAL ENDS */}
    </div>
  );
};

export default AddDesignation;
