export const ALL_POLICIES_REQUEST = "ALL_POLICIES_REQUEST"
export const ALL_POLICIES_SUCCESS = "ALL_POLICIES_SUCCESS"
export const ALL_POLICIES_FAIL = "ALL_POLICIES_FAIL"


export const CREATE_POLICY_REQUEST = "CREATE_POLICY_REQUEST"
export const CREATE_POLICY_SUCCESS = "CREATE_POLICY_SUCCESS"
export const CREATE_POLICY_FAIL = "CREATE_POLICY_FAIL"


export const POLICY_DETAILS_REQUEST = "POLICY_DETAILS_REQUEST"
export const POLICY_DETAILS_SUCCESS = "POLICY_DETAILS_SUCCESS"
export const POLICY_DETAILS_FAIL = "POLICY_DETAILS_FAIL"


export const USER_POLICY_REQUEST = "USER_POLICY_REQUEST"
export const USER_POLICY_SUCCESS = "USER_POLICY_SUCCESS"
export const USER_POLICY_FAIL = "USER_POLICY_FAIL"

export const CLEAR_POLICY_ERROR = "CLEAR_POLICY_ERROR"
export const CLEAR_POLICY_STATE = "CLEAR_POLICY_STATE"

