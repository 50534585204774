import React, { useEffect, useState } from "react";
import SidebarLayout from "../components/layouts/SidebarLayout";
import PageTitle from "../components/PageTitle";
import CustomForm from "../components/CustomForm";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  clearRegisterState,
  getUserByPan,
  userRegisterAction,
} from "../redux/actions/userAction";
import { toast } from "react-toastify";
import LoadingButton from "../components/LoadingButton";
import {
  clearDesignationErrors,
  getAllDesignation,
} from "../redux/actions/designationAction";

const NewRegistration = () => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    fullName: "",
    email: "",
    phone: "",
    designation: "",
    panNumber: "",
    password: "",
    dob: "",
    gender: "",
    role: "",
    fatherName: "",
    village: "",
    city: "",
    state: "",
    pinCode: "",
    referredBy: "",
  });

  console.log("userData", userData);

  const {
    fullName,
    email,
    phone,
    designation,
    panNumber,
    password,
    dob,
    gender,
    role,
    fatherName,
    village,
    city,
    state,
    pinCode,
    referredBy,
  } = userData;

  // INPUT CHANGE HANDLER
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const { loading, userInfo, error } = useSelector((state) => state.register);

  useEffect(() => {
    if (userInfo?.success) {
      toast.success(userInfo?.message);
      dispatch(clearRegisterState());
    }

    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
  }, [dispatch, error, userInfo?.message, userInfo?.success]);

  // NEW REGISTRATION HANDLER
  const handleNewRegistration = () => {
    dispatch(
      userRegisterAction(
        fullName,
        email,
        phone,
        designation,
        panNumber,
        password,
        dob,
        gender,
        role,
        fatherName,
        village,
        city,
        state,
        pinCode,
        referredBy
      )
    );
  };

  // GET USER BY PAN NUMBER
  const {
    data: agentData,
    loading: agentLoading,
    error: agentError,
  } = useSelector((state) => state.getUserByPanNumber);

  useEffect(() => {
    if (referredBy.length === 10) dispatch(getUserByPan(referredBy));
  }, [dispatch, referredBy.length, referredBy]);

  useEffect(() => {
    if (agentError) {
      toast.error(agentError);
      dispatch(clearErrors());
    }
  }, [agentError, dispatch]);

  // GET ALL DESIGNATION START
  const {
    loading: allDesignationLoading,
    data: allDesignationData,
    error: allDesignationError,
  } = useSelector((state) => state.getAllDesignations);

  const designationList = allDesignationData?.designations;

  useEffect(() => {
    dispatch(getAllDesignation());
  }, [dispatch]);

  useEffect(() => {
    if (allDesignationError) {
      dispatch(clearDesignationErrors());
    }
  }, [allDesignationError, dispatch]);

  // GET ALL DESIGNATION ENDS

  return (
    <div className="registration_main">
      <SidebarLayout>
        <div className="registration_content">
          <PageTitle title="New Registration" />
          <div className="registration_data">
            <CustomForm>
              <div className="row g-3">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="registerAs">
                      Register As <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      id="registerAs"
                      onChange={handleInputChange}
                      name="role"
                      value={role}
                    >
                      <option>Select One Option</option>
                      <option value={12}>Admin</option>
                      <option value={11}>Associate</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="fullName">
                      Full Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="fullName"
                      placeholder="Ex: Rahul Singh"
                      onChange={handleInputChange}
                      name="fullName"
                      value={fullName}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="fatherName">
                      Father Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="fatherName"
                      placeholder="Father name of applicant"
                      onChange={handleInputChange}
                      name="fatherName"
                      value={fatherName}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="panNumber">
                      PAN Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="panNumber"
                      placeholder="Ex: CSQLD1234R"
                      value={panNumber}
                      name="panNumber"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="emailId">
                      Email ID <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="emailId"
                      placeholder="Ex: example@test.com"
                      value={email}
                      name="email"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="phone">
                      Mobile Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="phone"
                      placeholder="Ex: 9876543210"
                      value={phone}
                      name="phone"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="dob">
                      Date of Birth <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="dob"
                      placeholder="Ex: 26-11-2000"
                      value={dob}
                      name="dob"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="designation">
                      Designation
                    </label>
                    <select
                      className="form-select"
                      id="designation"
                      onChange={handleInputChange}
                      name="designation"
                      value={designation}
                    >
                      <option value="">Select One Option</option>
                      {designationList?.length &&
                        designationList?.map((item) => (
                          <option key={item?._id} value={item?._id}>{item?.title}</option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="gender">
                      Gender <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      id="gender"
                      onChange={handleInputChange}
                      name="gender"
                      value={gender}
                    >
                      <option>Select One Option</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="locality">
                      Locality/Village <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="locality"
                      placeholder="Ex: Locality/Village"
                      value={village}
                      name="village"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="City">
                      City <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="City"
                      placeholder="Ex: Rewari"
                      value={city}
                      name="city"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="state">
                      State <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="state"
                      placeholder="Ex: Haryana"
                      value={state}
                      name="state"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="pinCode">
                      PIN Code <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="pinCode"
                      placeholder="Ex: 123456"
                      value={pinCode}
                      name="pinCode"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="password">
                      Password for new account{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="password"
                      placeholder="Ex: Anckdirbias@$#$%1234"
                      value={password}
                      name="password"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row g-3 mt-1">
              <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="referredBy">
                      refferred By (refferral Code){" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="referredBy"
                      placeholder="Ex: ABCDE1234A"
                      value={referredBy}
                      name="referredBy"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="refferralUserName">
                      Refferal User's Name (Auto fetch by refferral ID){" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      style={{ color: "red" }}
                      type="text"
                      className="form-control"
                      id="refferralUserName"
                      readOnly
                      disabled
                      value={
                        agentLoading
                          ? "Loading"
                          : agentData?.user?.fullName
                          ? agentData?.user?.fullName
                          : ""
                      }
                      placeholder="Refferal User's Name (Auto fetch by refferral ID)"
                    />
                  </div>
                </div>
              </div>
              <button
                onClick={handleNewRegistration}
                className="btn btn-danger d-block mx-auto mt-3 text-uppercase"
              >
                {loading ? <LoadingButton /> : "Register"}
              </button>
            </CustomForm>
          </div>
        </div>
      </SidebarLayout>
    </div>
  );
};

export default NewRegistration;
