import React, { useEffect, useState } from "react";
import "../styles/addPolicy.scss";
import SidebarLayout from "../components/layouts/SidebarLayout";
import PageTitle from "../components/PageTitle";
import CustomForm from "../components/CustomForm";
import {
  clearPolicyErrors,
  createPolicy,
  clearPolicyState,
} from "../redux/actions/policyAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoadingButton from "../components/LoadingButton";
import { clearErrors, getUserByPan } from "../redux/actions/userAction";
import {
  clearCompanyErrors,
  getAllCompanies,
} from "../redux/actions/insurerCompanyAction";
import { useNavigate } from "react-router-dom";

const AddPolicy = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [policyData, setPolicyData] = useState({
    policyNumber: "",
    policyHolder: "",
    policyEmail: "",
    policyHolderPhone: "",
    policyType: "",
    policyHolderGender: "",
    insurerCompany: "",
    policyStartDate: "",
    policyEndDate: "",
    premium: "",
    policyReffererCommission: "",
    policyRefferer: "",
  });

  const {
    policyHolder,
    policyEmail,
    policyHolderPhone,
    policyType,
    policyNumber,
    policyHolderGender,
    insurerCompany,
    policyStartDate,
    policyEndDate,
    premium,
    policyReffererCommission,
    policyRefferer,
  } = policyData;

  // INPUT CHANGE HANDLER
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPolicyData({
      ...policyData,
      [name]: value,
    });
  };

  const handleCreatePolicy = () => {
    dispatch(
      createPolicy(
        policyNumber,
        policyType,
        policyHolder,
        policyEmail,
        policyHolderPhone,
        policyHolderGender,
        insurerCompany,
        policyStartDate,
        policyEndDate,
        premium,
        policyReffererCommission,
        policyRefferer
      )
    );
  };

  const { loading, data, error } = useSelector(
    (state) => state.createPolicyReducer
  );

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearPolicyErrors());
    }

    if (data?.success) {
      toast.success(data?.message);
      navigate("/all-policies");
      dispatch(clearPolicyState());
    }
  }, [data?.message, error, dispatch, data?.success, navigate]);

  // GET USER BY PAN NUMBER
  const {
    data: agentData,
    loading: agentLoading,
    error: agentError,
  } = useSelector((state) => state.getUserByPanNumber);

  useEffect(() => {
    if (policyRefferer.length === 10) dispatch(getUserByPan(policyRefferer));
  }, [dispatch, policyRefferer.length, policyRefferer]);

  useEffect(() => {
    if (agentError) {
      toast.error(agentError);
      dispatch(clearErrors());
    }
  }, [agentError, dispatch]);

  // GET ALL ATTACHED COMPANIES START
  const {
    loading: allCompaniesLoading,
    data: allCompaniesData,
    error: allCompaniesError,
  } = useSelector((state) => state.getAllCompanies);

  const insurerCompanyList = allCompaniesData?.companies;

  useEffect(() => {
    dispatch(getAllCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (allCompaniesError) {
      dispatch(clearCompanyErrors());
    }
  }, [dispatch, allCompaniesError]);
  // GET ALL ATTACHED COMPANIES ENDS

  return (
    <div className="AddPolicy_main">
      <SidebarLayout>
        <div className="AddPolicy_content">
          <PageTitle title="Add Policy" />
          <CustomForm>
            <div className="row g-3">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="policyType">
                    Policy Type <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="policyType"
                    placeholder="Policy Type"
                    name="policyType"
                    value={policyType}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="refferralId">
                    Refferal Code (PAN No of Refferal associate)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="refferralId"
                    placeholder="Refferral ID"
                    name="policyRefferer"
                    value={policyRefferer}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="refferralUserName">
                    Refferal User's Name (Auto fetch by refferral ID){" "}
                  </label>
                  <input
                    style={{ color: "red" }}
                    type="text"
                    className="form-control"
                    id="refferralUserName"
                    readOnly
                    disabled
                    value={
                      agentLoading
                        ? "Loading"
                        : agentData?.user?.fullName
                        ? agentData?.user?.fullName
                        : ""
                    }
                    placeholder="Refferal User's Name (Auto fetch by refferral ID)"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="policyHolder">
                    Policy Holder (Full Name){" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="policyHolder"
                    placeholder="Full Name of Policy Holder"
                    value={policyHolder}
                    name="policyHolder"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="policyHolderEmail">
                    Policy Holder's Email address{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="policyHolderEmail"
                    placeholder="name@example.com"
                    value={policyEmail}
                    name="policyEmail"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="policyHoldermobile">
                    Policy Holder's Mobile Number{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="policyHoldermobile"
                    placeholder="82955*****"
                    value={policyHolderPhone}
                    name="policyHolderPhone"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="policyHolderGender">
                    Policy Holder (Gender){" "}
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    id="policyHolderGender"
                    name="policyHolderGender"
                    value={policyHolderGender}
                    onChange={handleInputChange}
                  >
                    <option>Select One</option>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Other</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="policynumber">
                    Policy Number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="policynumber"
                    placeholder="XDC-G000XWDSW6587327"
                    value={policyNumber}
                    name="policyNumber"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="insurerCompanyName">
                    Insurer Company <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    id="insurerCompanyName"
                    name="insurerCompany"
                    value={insurerCompany}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Insurer Company Name</option>
                    {insurerCompanyList?.length &&
                      insurerCompanyList.map((item) => {
                        return (
                          <option key={item?._id} value={item?._id}>{item?.companyName}</option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="policyStartDate">
                    Policy Start Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="policyStartDate"
                    placeholder="26-11-2000"
                    value={policyStartDate}
                    name="policyStartDate"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="policyEndDate">
                    Policy End Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="policyEndDate"
                    placeholder="26-11-2001"
                    value={policyEndDate}
                    name="policyEndDate"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="policyPremium">
                    Premium (in Rs) <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="policyPremium"
                    placeholder="Fill Policy premium"
                    value={premium}
                    name="premium"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="refferalCommssion">
                    Points of Refferal Associate{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="refferalCommssion"
                    placeholder="Points (as Number)"
                    value={policyReffererCommission}
                    name="policyReffererCommission"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <button
              onClick={handleCreatePolicy}
              className="btn btn-success d-block mx-auto mt-3 text-uppercase"
            >
              {loading ? <LoadingButton /> : "Create New Policy"}
            </button>
          </CustomForm>
        </div>
      </SidebarLayout>
    </div>
  );
};

export default AddPolicy;
