import React, { useEffect, useState } from "react";
import "../styles/addPolicy.scss";
import SidebarLayout from "../components/layouts/SidebarLayout";
import PageTitle from "../components/PageTitle";
import CustomForm from "../components/CustomForm";
import {
  clearPolicyErrors,
  createPolicy,
  clearPolicyState,
} from "../redux/actions/policyAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoadingButton from "../components/LoadingButton";

const UpdatePolicy = () => {
  const dispatch = useDispatch();

  const [policyData, setPolicyData] = useState({
    policyNumber: "",
    policyHolder: "",
    policyEmail: "",
    policyHolderPhone: "",
    policyHolderDob: "",
    policyHolderGender: "",
    policyHolderFatherName: "",
    policyHolderMotherName: "",
    policyHolderAddress: "",
    insurerCompany: "",
    policyStartDate: "",
    policyEndDate: "",
    premium: "",
    policyReffererCommission: "",
    policyRefferer: "",
  });

  const {
    policyHolder,
    policyEmail,
    policyHolderPhone,
    policyNumber,
    policyHolderDob,
    policyHolderGender,
    policyHolderFatherName,
    policyHolderMotherName,
    policyHolderAddress,
    insurerCompany,
    policyStartDate,
    policyEndDate,
    premium,
    policyReffererCommission,
    policyRefferer,
  } = policyData;

  // INPUT CHANGE HANDLER
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPolicyData({
      ...policyData,
      [name]: value,
    });
  };

  const handleCreatePolicy = () => {
    dispatch(
      createPolicy(
        policyNumber,
        policyHolder,
        policyEmail,
        policyHolderPhone,
        policyHolderDob,
        policyHolderGender,
        policyHolderFatherName,
        policyHolderMotherName,
        policyHolderAddress,
        insurerCompany,
        policyStartDate,
        policyEndDate,
        premium,
        policyReffererCommission,
        policyRefferer
      )
    );
  };

  const { laoding, data, error } = useSelector(
    (state) => state.createPolicyReducer
  );

  console.log("error", error);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearPolicyErrors());
    }

    if (data?.success) {
      toast.success(data?.message);
      dispatch(clearPolicyState());
    }
  }, [data?.message, error, dispatch, data?.success]);

  return (
    <div className="AddPolicy_main">
      <SidebarLayout>
        <div className="AddPolicy_content">
          <PageTitle title="Update Policy" />
          <CustomForm>
            <div className="row g-3">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="refferralId">
                    Refferal Code (Reg No of Refferal){" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="refferralId"
                    placeholder="Refferral ID"
                    name="policyRefferer"
                    value={policyRefferer}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="refferralUserName">
                    Refferal User's Name (Auto fetch by refferral ID){" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="refferralUserName"
                    readOnly
                    disabled
                    placeholder="Refferal User's Name (Auto fetch by refferral ID)"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="policyHolder">
                    Policy Holder (Full Name){" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="policyHolder"
                    placeholder="Full Name of Policy Holder"
                    value={policyHolder}
                    name="policyHolder"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="policyHolderEmail">
                    Policy Holder's Email address{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="policyHolderEmail"
                    placeholder="name@example.com"
                    value={policyEmail}
                    name="policyEmail"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="policyHoldermobile">
                    Policy Holder's Mobile Number{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="policyHoldermobile"
                    placeholder="82955*****"
                    value={policyHolderPhone}
                    name="policyHolderPhone"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="policyHolderfathername">
                    Policy Holder's Father Name{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="policyHolderfathername"
                    placeholder="Mr. ......"
                    value={policyHolderFatherName}
                    name="policyHolderFatherName"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="policyHoldermothername">
                    Policy Holder's Mother Name{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="policyHoldermothername"
                    placeholder="Mrs. ......"
                    value={policyHolderMotherName}
                    name="policyHolderMotherName"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="policyHolderGender">
                    Policy Holder (Gender){" "}
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    id="policyHolderGender"
                    name="policyHolderGender"
                    value={policyHolderGender}
                    onChange={handleInputChange}
                  >
                    <option>Select One</option>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Other</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="policyHolderaddress">
                    Policy Holder's Address{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="policyHolderaddress"
                    placeholder="Ex: 123, Gurgaon, 122000"
                    value={policyHolderAddress}
                    name="policyHolderAddress"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="policyHolderdob">
                    Policy Holder's Date of Birth{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="policyHolderdob"
                    placeholder="26-11-2000"
                    value={policyHolderDob}
                    name="policyHolderDob"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="policynumber">
                    Policy Number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="policynumber"
                    placeholder="Enter policy number"
                    value={policyNumber}
                    name="policyNumber"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="insurerCompanyName">
                    Insurer Company <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    id="insurerCompanyName"
                    name="insurerCompany"
                    value={insurerCompany}
                    onChange={handleInputChange}
                  >
                    <option>Select Insurer Company Name</option>
                    <option>TATA</option>
                    <option>Mahindra</option>
                    <option>Star</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="policyStartDate">
                    Policy Start Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="policyStartDate"
                    placeholder="26-11-2000"
                    value={policyStartDate}
                    name="policyStartDate"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="policyEndDate">
                    Policy End Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="policyEndDate"
                    placeholder="26-11-2001"
                    value={policyEndDate}
                    name="policyEndDate"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="policyPremium">
                    Premium (in Rs) <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="policyPremium"
                    placeholder="Fill Policy premium"
                    value={premium}
                    name="premium"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="refferalCommssion">
                    Commission of Refferal Associate{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="refferalCommssion"
                    placeholder="Commission (in Rs)"
                    value={policyReffererCommission}
                    name="policyReffererCommission"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <button
              onClick={handleCreatePolicy}
              className="btn btn-success d-block mx-auto mt-3 text-uppercase"
            >
              {laoding ? <LoadingButton /> : "Create New Policy"}
            </button>
          </CustomForm>
        </div>
      </SidebarLayout>
    </div>
  );
};

export default UpdatePolicy;
