const roles = [
  {
    roleId: 10,
    title: "Super Admin",
  },
  {
    roleId: 11,
    title: "Associate",
  },
  {
    roleId: 12,
    title: "Admin",
  },
];

export const jsrRole = (id) => {
  const user = roles.filter((ele) => id == ele.roleId);
  return user[0]?.title;
};
