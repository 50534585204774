import React from "react";
import "./App.css";
import ScrollToTop from "./components/ScrollToTop.js";
import { Routes, Route, Outlet } from "react-router-dom";
import Login from "./pages/Login.js";
import Dashboard from "./pages/Dashboard.js";
import Agents from "./pages/Agents.js";
import AddPolicy from "./pages/AddPolicy.js";
import UpdatePolicy from "./pages/UpdatePolicy.js";
import Commission from "./pages/Commission.js";
import AddDesignation from "./pages/AddDesignation.js";
import AllPolicies from "./pages/AllPolicies.js";
import Revenue from "./pages/Revenue.js";
import ImportantUpdates from "./pages/ImportantUpdates.js";
import RegisterNewAgent from "./pages/NewRegisteration.js";
import Admins from "./pages/Admins.js";
import UserProfile from "./pages/UserProfile.js";
import { useDispatch, useSelector } from "react-redux";
import { clearState } from "./redux/actions/userAction.js";
import RedirectSpinner from "./components/RedirectSpinner.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/Loader.js";
import AttachedCompanies from "./pages/AttachedCompanies.js";

const App = () => {
  const dispatch = useDispatch();
  const { loading, isAuthenticated, userInfo } = useSelector(
    (state) => state.profile
  );

  const userRole = userInfo?.user?.role;

  let authenticatedAdmin = false;

  if (isAuthenticated && (userRole === 10 || userRole === 12)) {
    authenticatedAdmin = true;
  } else if (isAuthenticated && (userRole === 10 || userRole === 12)) {
    authenticatedAdmin = false;
    toast.warning("You're not a authorised user");
    localStorage.removeItem("jsrConfig");
    dispatch(clearState());
  }
  return (
    <div className="app">
      {loading ? (
        <Loader />
      ) : (
        <>
          <ScrollToTop />
          <ToastContainer />
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route
              path="/"
              element={authenticatedAdmin ? <Outlet /> : <RedirectSpinner />}
            >
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="agents" element={<Agents />} />
              <Route path="admins" element={<Admins />} />
              <Route path="/user-profile/:userId" element={<UserProfile />} />
              <Route path="add-policy" element={<AddPolicy />} />
              <Route path="policy/:policyId" element={<UpdatePolicy />} />
              <Route path="commisions" element={<Commission />} />
              <Route path="designations" element={<AddDesignation />} />
              <Route path="all-policies" element={<AllPolicies />} />
              <Route path="revenue" element={<Revenue />} />
              <Route path="important-update" element={<ImportantUpdates />} />
              <Route path="new-registration" element={<RegisterNewAgent />} />
              <Route path="attached-companies" element={<AttachedCompanies />} />
            </Route>
          </Routes>
        </>
      )}
    </div>
  );
};

export default App;
