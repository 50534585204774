import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const RedirectSpinner = ({ path = "/" }) => {
  const [count, setCount] = useState(5);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => --prevCount);
    }, 1000);
    count <= 0 &&
      navigate(`${path}`, {
        state: location.pathname,
      });
    return () => clearInterval(interval);
  }, [count, navigate, location, path]);

  return (
    <div
      className="text-center d-flex align-items-center bg-dark justify-content-center min-vh-100"
    >
      <div className="container">
        <h3 className="text-capitalize text-light fs-4">
          you do not have sufficient privileges to access the resource. <br />{" "}
          Redirecting to you in <span className="text-danger">{count}</span>{" "}
          second.
        </h3>
        <Link to="/" className="btn btn-danger mt-3 px-4 text-uppercase">
          Login
        </Link>
      </div>
    </div>
  );
};

export default RedirectSpinner;
