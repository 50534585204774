import React, { useEffect } from "react";
import SidebarLayout from "../components/layouts/SidebarLayout";
import PageTitle from "../components/PageTitle";
import CustomForm from "../components/CustomForm";
import NoData from "../components/NoData";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import usePaginate from "../hooks/usePaginate";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import {
  clearPolicyErrors,
  getAllPolicies,
} from "../redux/actions/policyAction";
import { toast } from "react-toastify";
import { jsrRole } from "../utils/userRoles";

const Commission = () => {
  const dispatch = useDispatch();

  // GET ALL POLICIES START

  const {
    loading,
    data: allPolicies,
    error,
  } = useSelector((state) => state.getAllPoliciesReducer);

  useEffect(() => {
    dispatch(getAllPolicies());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearPolicyErrors());
    }
  }, [error, dispatch]);
  // GET ALL POLICIES ENDS

  const policyList = allPolicies?.policies;

  let pointsData;
  if (policyList?.length) {
    const aggregatedPolicies =
      policyList &&
      policyList?.reduce((acc, policy) => {
        const referrerId = policy?.policyRefferer?._id;
        if (!acc[referrerId]) {
          acc[referrerId] = {
            ...policy?.policyRefferer,
            totalCommission: 0,
          };
        }
        acc[referrerId].totalCommission += policy?.policyReffererCommission;
        return acc;
      }, {});

    const result = Object.values(aggregatedPolicies);
    if (result?.length) {
      pointsData = result;
    }
  }

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick } = usePaginate(pointsData);
  // Pagination Hook Ends

  

  return loading ? (
    <Loader />
  ) : (
    <div className="AddPolicy_main">
      <SidebarLayout>
        <div className="AddPolicy_content">
          <PageTitle title="Points" />
          <CustomForm>
            <div className="row">
              <div className="col-md-6 col">
                <div className="form-group">
                  <label className="fw-bold" htmlFor="searchAgent">
                    Search Associates By Name/PAN
                  </label>
                  <input
                    type="search"
                    className="form-control"
                    id="searchAgent"
                    placeholder="Ex: Pankaj/CYNPP*****"
                  />
                </div>
              </div>
            </div>
          </CustomForm>
          <div className="all_agents mt-4">
            {!pointsData?.length ? (
              <NoData />
            ) : (
              <>
                <div className="table-responsive">
                  <table className="table  table-hover table-bordered customTable">
                    <thead>
                      <tr>
                        <th scope="col">Full Name</th>
                        <th scope="col">PAN Number</th>
                        <th scope="col">Role</th>
                        <th scope="col">Total Points</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.map((itm, itmIndex) => (
                        <tr key={itmIndex}>
                          <td>{itm?.fullName}</td>
                          <td>{itm?.panNumber}</td>
                          <td>{jsrRole(itm?.role)}</td>
                          <td>{itm?.totalCommission}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="pagination_sec">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<RxDoubleArrowRight />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={<RxDoubleArrowLeft />}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </SidebarLayout>
    </div>
  );
};

export default Commission;
