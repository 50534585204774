import React from "react";
import { styled } from "styled-components";

const Form = styled.form`
  padding: 15px;
  background-color: ${(props) => props.background};
  border-radius: 20px;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 1px 1px 4px 1px #000;

  .form-control {
    box-shadow: none;
  }
`;

const CustomForm = ({ children }) => {
  return (
    <Form onSubmit={(e) => e.preventDefault()} style={{background: "lightgrey"}}>
      {children}
    </Form>
  );
};

export default CustomForm;
