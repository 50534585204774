import React, { useEffect, useState } from "react";
import "../styles/login.scss";
import { clearErrors, login } from "../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import LoadingButton from "../components/LoadingButton";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [panNumber, setPanNumber] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // email, panNumber, password

  const handleLogin = (e) => {
    e.preventDefault();
    if (!panNumber && !password) {
      toast.warning("Please fill the input values");
    } else {
      dispatch(login("", panNumber, password));
    }
  };

  const { loading, userInfo, error } = useSelector((state) => state.userLogin);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }

    if (userInfo?.success) {
      navigate("/dashboard");
    }
  }, [error, dispatch, navigate, userInfo?.success]);

  const positions = [
    "0% 10%",
    "10% 10%",
    "10% 30%",
    "10% 40%",
    "80% 80%",
    "40% 80%",
  ];
  function getRandomPosition() {
    const randomIndex = Math.floor(Math.random() * positions.length);
    return positions[randomIndex];
  }
  return (
    <div
      className="custom_login d-flex align-items-center justify-content-center vh-100 bg-light"
      style={{ backgroundPosition: getRandomPosition() }}
    >
      <div className="card shadow-lg" style={{ width: "350px" }}>
        <div className="card-body">
          <h3 className="card-title text-center mb-4">Welcome Back, Login</h3>
          <form onSubmit={handleLogin}>
            <div className="mb-3">
              <label htmlFor="uniqueId" className="form-label">
                Unique ID
              </label>
              <input
                type="text"
                className="form-control"
                id="uniqueId"
                aria-describedby="uniqueId"
                onChange={(e) => setPanNumber(e.target.value)}
                required
              />
            </div>
            <div className="mb-3 position-relative">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div
                className="input_icon position-absolute"
                style={{ top: "36px", right: "7px" }}
                title={!showPassword ? "Show Password" : "Hide Password"}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
              </div>
            </div>
            <div className="d-grid">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading || !panNumber || !password ? true : false}
              >
                {loading ? <LoadingButton /> : "Login"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
