import {
  CLEAR_STATE,
  REGISTER_FAIL,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_SUCCESS,
  USER_PROFILE_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_LOGOUT_REQUEST,
  CLEAR_ERRORS,
  GET_USER_BY_PAN_REQUEST,
  GET_USER_BY_PAN_SUCCESS,
  GET_USER_BY_PAN_FAIL,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAIL,
  CLEAR_REGISTER_STATE,
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  UPDATE_USER_DETAILS_REQUEST,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAIL,
  CLEAR_UPDATE_STATE,
  CHNAGE_PASSWORD_REQUEST,
  CHNAGE_PASSWORD_SUCCESS,
  CHNAGE_PASSWORD_FAIL,
} from "../constants/userConstant";
import { axiosInstance } from "../../utils/axiosConfig";

//   FOR USER REGISTRATION
export const userRegisterAction =
  (
    fullName,
    email,
    phone,
    designation,
    panNumber,
    password,
    dob,
    gender,
    role,
    fatherName,
    village,
    city,
    state,
    pinCode,
    referredBy
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: REGISTER_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("jsrConfig")
        ? JSON.parse(sessionStorage.getItem("jsrConfig"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        `/register`,
        {
          fullName,
          email,
          phone,
          designation,
          panNumber,
          password,
          dob,
          gender,
          role,
          fatherName,
          village,
          city,
          state,
          pinCode,
          referredBy,
        },
        config
      );
      dispatch({
        type: REGISTER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REGISTER_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// USER LOGIN ACTION ==--==--==--==--==--==--==--
export const login =
  (email, panNumber, password) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_LOGIN_REQUEST,
      });

      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      const { data } = await axiosInstance.post(
        `/login`,
        {
          email,
          panNumber,
          password,
        },
        config
      );

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      sessionStorage.setItem(
        "jsrConfig",
        JSON.stringify(getState().userLogin?.userInfo)
      );
    } catch (error) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// USER LOGOUT
export const logout = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGOUT_REQUEST,
    });

    sessionStorage.removeItem("jsrConfig");

    dispatch({ type: CLEAR_STATE });

    dispatch({
      type: USER_LOGOUT_SUCCESS,
      payload: null,
    });
  } catch (error) {
    alert(error);
  }
};

// GET USER PROFILE DETAILS
export const userProfile = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_PROFILE_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("jsrConfig")
      ? JSON.parse(sessionStorage.getItem("jsrConfig"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/profile`, config);

    dispatch({
      type: USER_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_PROFILE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET USER BY PAN NUMBER
export const getUserByPan = (panNumber) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_BY_PAN_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("jsrConfig")
      ? JSON.parse(sessionStorage.getItem("jsrConfig"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(
      `/user-by-pan/${panNumber}`,
      config
    );

    dispatch({
      type: GET_USER_BY_PAN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_BY_PAN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET ALL USERS
export const getUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_USERS_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("jsrConfig")
      ? JSON.parse(sessionStorage.getItem("jsrConfig"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/all-users`, config);

    dispatch({
      type: GET_ALL_USERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_USERS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET USER DETAILS BY ID -- ADMIN
export const getUserDetails = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_DETAILS_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("jsrConfig")
      ? JSON.parse(sessionStorage.getItem("jsrConfig"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/user/${userId}`, config);

    dispatch({
      type: GET_USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// UPDATE USER DETAILS BY ID -- ADMIN
export const updateUserDetails =
  (
    userId,
    isActive,
    fullName,
    email,
    phone,
    designation,
    panNumber,
    dob,
    gender,
    role,
    fatherName,
    village,
    city,
    state,
    pinCode,
    referredBy
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_USER_DETAILS_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("jsrConfig")
        ? JSON.parse(sessionStorage.getItem("jsrConfig"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.put(
        `/update-profile/${userId}`,
        {
          isActive,
          fullName,
          email,
          phone,
          designation,
          panNumber,
          dob,
          gender,
          role,
          fatherName,
          village,
          city,
          state,
          pinCode,
          referredBy,
        },
        config
      );

      dispatch({
        type: UPDATE_USER_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_USER_DETAILS_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// UPDATE USER PASSWORD -- PUT
export const updateUserPassword = (userId, password) => async (dispatch) => {
  try {
    dispatch({
      type: CHNAGE_PASSWORD_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("jsrConfig")
      ? JSON.parse(sessionStorage.getItem("jsrConfig"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.put(
      `/change-password?userId=${userId}`,
      {
        password,
      },
      config
    );

    dispatch({
      type: CHNAGE_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHNAGE_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

// CLEAR STATE ACTION FUNCTION
export const clearState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATE });
};

// CLEAR REGISTER STATE ACTION FUNCTION
export const clearRegisterState = () => async (dispatch) => {
  dispatch({ type: CLEAR_REGISTER_STATE });
};

// CLEAR UPDATE STATE ACTION FUNCTION
export const clearUpdateState = () => async (dispatch) => {
  dispatch({ type: CLEAR_UPDATE_STATE });
};
