import {
  ALL_POLICIES_FAIL,
  ALL_POLICIES_REQUEST,
  ALL_POLICIES_SUCCESS,
  CLEAR_POLICY_ERROR,
  CLEAR_POLICY_STATE,
  CREATE_POLICY_FAIL,
  CREATE_POLICY_REQUEST,
  CREATE_POLICY_SUCCESS,
} from "../constants/policyConstant";

// CREATE NEW POLICY REDUCER
export const createPolicyReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_POLICY_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case CREATE_POLICY_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case CREATE_POLICY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_POLICY_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_POLICY_STATE:
      return {
        data: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET ALL POLICIES REDUCER
export const getAllPoliciesReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_POLICIES_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case ALL_POLICIES_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case ALL_POLICIES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_POLICY_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
