import {
  ALL_DESIGNATIONS_REQUEST,
  ALL_DESIGNATIONS_SUCCESS,
  ALL_DESIGNATIONS_FAIL,
  CLEAR_DESIGNATION_ERROR,
  CREATE_DESIGNATIONS_REQUEST,
  CREATE_DESIGNATIONS_SUCCESS,
  CREATE_DESIGNATIONS_FAIL,
  CLEAR_DESIGNATION_STATE,
  UPADTE_DESIGNATION_REQUEST,
  UPADTE_DESIGNATION_SUCCESS,
  UPADTE_DESIGNATION_FAIL,
  DELETE_DESIGNATION_REQUEST,
  DELETE_DESIGNATION_SUCCESS,
  DELETE_DESIGNATION_FAIL,
} from "../constants/designationConstant.js";

// GET ALL DESIGNATIONS REDUCER
export const getAllDesignationsReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_DESIGNATIONS_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case ALL_DESIGNATIONS_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case ALL_DESIGNATIONS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_DESIGNATION_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// CREATE DESIGNATIONS REDUCER
export const createDesignationsReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_DESIGNATIONS_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case CREATE_DESIGNATIONS_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case CREATE_DESIGNATIONS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_DESIGNATION_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_DESIGNATION_STATE:
      return {
        data: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// UPDATE DESIGNATION REDUCER
export const updateDesignationReducer = (state = {}, action) => {
  switch (action.type) {
    case UPADTE_DESIGNATION_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case UPADTE_DESIGNATION_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case UPADTE_DESIGNATION_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_DESIGNATION_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_DESIGNATION_STATE:
      return {
        data: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// DELETE DESIGNATION REDUCER
export const deleteDesignationReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_DESIGNATION_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case DELETE_DESIGNATION_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case DELETE_DESIGNATION_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_DESIGNATION_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_DESIGNATION_STATE:
      return {
        data: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
