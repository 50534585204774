import { useState } from "react";
import "../../styles/sidebarLayout.scss";
import { NavLink } from "react-router-dom";
import {
  MdOutlineDashboard,
  MdOutlinePolicy,
  MdOutlineSupportAgent,
  MdMonetizationOn,
  MdLogout,
} from "react-icons/md";
import { SiAwsorganizations, SiCodesignal } from "react-icons/si";
import { logout } from "../../redux/actions/userAction";
import { useDispatch } from "react-redux";
import { useAuth } from "../../context/userContext";
import { logo } from "../../assets/images";

const SidebarLayout = ({ children }) => {
  const dispatch = useDispatch();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { auth } = useAuth();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="d-flex">
      <div
        className={`sidebar bg-dark text-white ${isSidebarOpen ? "open" : ""}`}
      >
        <div className="scrollable_sec">
          <img
            className="d-block mx-auto"
            width={150}
            height="auto"
            src={logo}
            alt="jsr-logo"
          />
          <ul className="list-unstyled p-3">
            <li className="mb-2">
              <NavLink
                to="/dashboard"
                className="text-dark bg-light d-flex align-items-center rounded p-2 text-decoration-none"
              >
                <MdOutlineDashboard className="d-flex me-1 fs-4" /> Dashboard
              </NavLink>
            </li>
            <li className="mb-2">
              <NavLink
                to="/add-policy"
                className="text-dark bg-light d-flex align-items-center rounded p-2 text-decoration-none"
              >
                <MdOutlinePolicy className="d-flex me-1 fs-4" /> Add Policy
              </NavLink>
            </li>
            <li className="mb-2">
              <NavLink
                to="/admins"
                className="text-dark bg-light d-flex align-items-center rounded p-2 text-decoration-none"
              >
                <MdOutlineSupportAgent className="d-flex me-1 fs-4" /> Admins
              </NavLink>
            </li>
            <li className="mb-2">
              <NavLink
                to="/agents"
                className="text-dark bg-light d-flex align-items-center rounded p-2 text-decoration-none"
              >
                <MdOutlineSupportAgent className="d-flex me-1 fs-4" /> Associates
              </NavLink>
            </li>
            <li className="mb-2">
              <NavLink
                to="/commisions"
                className="text-dark bg-light d-flex align-items-center rounded p-2 text-decoration-none"
              >
                <MdMonetizationOn className="d-flex me-1 fs-4" /> Points
              </NavLink>
            </li>
            <li className="mb-2">
              <NavLink
                to="/designations"
                className="text-dark bg-light d-flex align-items-center rounded p-2 text-decoration-none"
              >
                <SiCodesignal className="d-flex me-1 fs-4" /> Designations
              </NavLink>
            </li>
            <li className="mb-2">
              <NavLink
                to="/attached-companies"
                className="text-dark bg-light d-flex align-items-center rounded p-2 text-decoration-none"
              >
                <SiAwsorganizations className="d-flex me-1 fs-4" /> Insurer Companies
              </NavLink>
            </li>
            <li className="mb-2">
              <NavLink
                onClick={() => dispatch(logout())}
                to="/"
                className="text-white bg-danger d-flex align-items-center rounded p-2 text-decoration-none"
              >
                <MdLogout className="d-flex me-1 fs-4" /> Logout
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="user_profile">
          <div className="d-flex align-items-center justify-content-center bg-light text-dark position-absolute bottom-0 w-100">
            <span>
              <img
                className="d-block mr-1"
                width={50}
                height="auto"
                src="./jsr-logo.png"
                alt="jsr-logo"
              />
            </span>
            <span>{auth?.user?.fullName}</span>
          </div>
        </div>
      </div>
      <div className="childrenContent flex-grow-1">
        {/* OPEN CLOSE BUTTON START */}
        {/* <button className="btn btn-primary m-3" onClick={toggleSidebar}>
          {isSidebarOpen ? "Hide Sidebar" : "Show Sidebar"}
        </button> */}
        {/* OPEN CLOSE BUTTON ENDS */}
        <div className="p-3">{children}</div>
      </div>
    </div>
  );
};

export default SidebarLayout;
