import React, { useEffect } from "react";
import SidebarLayout from "../components/layouts/SidebarLayout";
import PageTitle from "../components/PageTitle";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { clearErrors, getUsers } from "../redux/actions/userAction";
import Loader from "../components/Loader";
import usePaginate from "../hooks/usePaginate";
import { FaRegEdit } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import NoData from "../components/NoData";
import CustomForm from "../components/CustomForm";

const Admins = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading, data, error } = useSelector((state) => state.getAllUsers);

  const allUsers = data?.users;

  const allAdmins = [];
  allUsers?.forEach((item) => {
    (item?.role === 10 || item?.role === 12) && allAdmins.push(item);
  });

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
  }, [error, dispatch]);

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick } = usePaginate(allAdmins);
  // Pagination Hook Ends

  return loading ? (
    <Loader />
  ) : (
    <div className="AddPolicy_main">
      <SidebarLayout>
        <div className="AddPolicy_content">
          <PageTitle title="Admins" />
          <div className="d-flex">
            <CustomForm>
              <div className="col-12">
                <div className="form-group">
                  <input
                    type="search"
                    className="form-control"
                    id="search"
                    placeholder="Search by PAN Number/Name"
                  />
                </div>
              </div>
            </CustomForm>
            <div className="ms-auto">
              <button
                className="btn btn-primary ms-auto d-block d-flex h-auto"
                onClick={() => navigate("/new-registration")}
              >
                Register New Admin
              </button>
            </div>
          </div>
          <div className="agents_table mt-4">
            {!allAdmins.length ? (
              <NoData />
            ) : (
              <>
                <div className="table-responsive">
                  <table className="table  table-hover table-bordered customTable">
                    <thead>
                      <tr>
                        <th scope="col">Reg ID</th>
                        <th scope="col">Full Name</th>
                        <th scope="col">Joining Date</th>
                        <th scope="col">Refferd By</th>
                        <th scope="col">Full Address</th>
                        <th scope="col">Status</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.map((itm, itmIndex) => (
                        <tr key={itmIndex}>
                          <td>{itm?.panNumber}</td>
                          <td>{itm?.fullName}</td>
                          <td>
                            {moment(itm?.createdAt).format("MMMM DD, YYYY")} at{" "}
                            {moment(itm?.createdAt).format("DD:MM A")}
                          </td>
                          <td>
                            {itm?.referredBy?.fullName}(
                            {itm?.referredBy?.panNumber})
                          </td>
                          <td>
                            {itm?.village}, {itm?.city}, {itm?.state},{" "}
                            {itm?.pinCode}
                          </td>
                          <td>
                            {itm?.isActive ? (
                              <span className="badge badge-pill bg-success">
                                Active
                              </span>
                            ) : (
                              <span className="badge badge-pill bg-danger">
                                Inactive
                              </span>
                            )}
                          </td>
                          <td>
                            <div className="d-flex">
                              <button
                                className="btn d-flex btn-primary"
                                title="Edit/See Details"
                                onClick={() =>
                                  navigate(`/user-profile/${itm?._id}`)
                                }
                              >
                                <FaRegEdit />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {allAdmins.length > 10 && (
                  <div className="pagination_sec">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={<RxDoubleArrowRight />}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={2}
                      pageCount={pageCount}
                      previousLabel={<RxDoubleArrowLeft />}
                      renderOnZeroPageCount={null}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </SidebarLayout>
    </div>
  );
};

export default Admins;
