export const ALL_COMPANIES_REQUEST = "ALL_COMPANIES_REQUEST";
export const ALL_COMPANIES_SUCCESS = "ALL_COMPANIES_SUCCESS";
export const ALL_COMPANIES_FAIL = "ALL_COMPANIES_FAIL";

export const CREATE_COMPANY_REQUEST = "CREATE_COMPANY_REQUEST";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_FAIL = "CREATE_COMPANY_FAIL";

export const UPADTE_COMPANY_REQUEST = "UPADTE_COMPANY_REQUEST";
export const UPADTE_COMPANY_SUCCESS = "UPADTE_COMPANY_SUCCESS";
export const UPADTE_COMPANY_FAIL = "UPADTE_COMPANY_FAIL";

export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAIL = "DELETE_COMPANY_FAIL";

export const CLEAR_COMPANY_ERROR = "CLEAR_COMPANY_ERROR";
export const CLEAR_COMPANY_STATE = "CLEAR_COMPANY_STATE";