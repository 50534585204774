import { axiosInstance } from "../../utils/axiosConfig.js";
import {
  ALL_DESIGNATIONS_REQUEST,
  ALL_DESIGNATIONS_SUCCESS,
  ALL_DESIGNATIONS_FAIL,
  CLEAR_DESIGNATION_ERROR,
  CREATE_DESIGNATIONS_REQUEST,
  CREATE_DESIGNATIONS_SUCCESS,
  CREATE_DESIGNATIONS_FAIL,
  CLEAR_DESIGNATION_STATE,
  UPADTE_DESIGNATION_REQUEST,
  UPADTE_DESIGNATION_SUCCESS,
  UPADTE_DESIGNATION_FAIL,
  DELETE_DESIGNATION_REQUEST,
  DELETE_DESIGNATION_SUCCESS,
  DELETE_DESIGNATION_FAIL,
} from "../constants/designationConstant.js";

// CREATE DESIGNATION
export const createDesignation = (title) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_DESIGNATIONS_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("jsrConfig")
      ? JSON.parse(sessionStorage.getItem("jsrConfig"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.post(
      `/create-designation`,
      { title },
      config
    );

    dispatch({
      type: CREATE_DESIGNATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_DESIGNATIONS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET ALL DESIGNATION
export const getAllDesignation = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_DESIGNATIONS_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("jsrConfig")
      ? JSON.parse(sessionStorage.getItem("jsrConfig"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/get-designations`, config);

    dispatch({
      type: ALL_DESIGNATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_DESIGNATIONS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// UPDATE DESIGNATION
export const updateDesignation = (designationId, title) => async (dispatch) => {
  try {
    dispatch({
      type: UPADTE_DESIGNATION_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("jsrConfig")
      ? JSON.parse(sessionStorage.getItem("jsrConfig"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.put(
      `/designation/${designationId}`,
      { title },
      config
    );

    dispatch({
      type: UPADTE_DESIGNATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPADTE_DESIGNATION_FAIL,
      payload: error.response.data.message,
    });
  }
};

// DELETE DESIGNATION
export const deleteDesignation = (designationId) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_DESIGNATION_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("jsrConfig")
      ? JSON.parse(sessionStorage.getItem("jsrConfig"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(
      `/designation/${designationId}`,
      config
    );

    dispatch({
      type: DELETE_DESIGNATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_DESIGNATION_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearDesignationErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_DESIGNATION_ERROR });
};

// CLEAR STATE ACTION FUNCTION
export const clearDesignationState = () => async (dispatch) => {
  dispatch({ type: CLEAR_DESIGNATION_STATE });
};
