import { axiosInstance } from "../../utils/axiosConfig";
import {
  ALL_POLICIES_FAIL,
  ALL_POLICIES_REQUEST,
  ALL_POLICIES_SUCCESS,
  CLEAR_POLICY_ERROR,
  CLEAR_POLICY_STATE,
  CREATE_POLICY_FAIL,
  CREATE_POLICY_REQUEST,
  CREATE_POLICY_SUCCESS,
} from "../constants/policyConstant";

// CREATE NEW POLICY
export const createPolicy =
  (
    policyNumber,
    policyType,
    policyHolder,
    policyEmail,
    policyHolderPhone,
    policyHolderGender,
    insurerCompany,
    policyStartDate,
    policyEndDate,
    premium,
    policyReffererCommission,
    policyRefferer
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_POLICY_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("jsrConfig")
        ? JSON.parse(sessionStorage.getItem("jsrConfig"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        `/create-policy`,
        {
          policyNumber,
          policyType,
          policyHolder,
          policyEmail,
          policyHolderPhone,
          policyHolderGender,
          insurerCompany,
          policyStartDate,
          policyEndDate,
          premium,
          policyReffererCommission,
          policyRefferer,
        },
        config
      );

      dispatch({
        type: CREATE_POLICY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_POLICY_FAIL,
        payload: error.response.data.message,
      });
    }
  };

//   GET ALL POLICIES
export const getAllPolicies = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_POLICIES_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("jsrConfig")
      ? JSON.parse(sessionStorage.getItem("jsrConfig"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/policies`, config);

    dispatch({
      type: ALL_POLICIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_POLICIES_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearPolicyErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_POLICY_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearPolicyState = () => async (dispatch) => {
  dispatch({ type: CLEAR_POLICY_STATE });
};
